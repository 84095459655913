import React from 'react';
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { useLocation } from '@reach/router';

const Footer = (locale) => {
  const location = useLocation();
  return (
    <nav className="d-flex justify-content-center navbar bg-secondary">
      <div className="d-flex align-items-center flex-column flex-nav-row ">
        <span className="nav-item col-nav-auto d-flex justify-content-center">
          <Link className="nav-link text-white" to={`/${locale.locale}/`} >
            Home
          </Link>
        </span>
        <div className="separator-vertical-footer d-none d-nav-block" />
        <div className="separator-horizontal-footer d-block d-nav-none" />
        <span className="nav-item col-nav-auto d-flex justify-content-center">
          <Link className="nav-link text-white" to={`${location.pathname}`} >
            <FormattedMessage id="footer.top" />
          </Link>
        </span>
        <div className="separator-vertical-footer d-none d-nav-block" />
        <div className="separator-horizontal-footer d-block d-nav-none" />
        <span className="nav-item col-nav-auto d-flex justify-content-center">
          <Link className="nav-link text-white" to={`/${locale.locale}/legal/`} activeClassName="fw-bold">
            <FormattedMessage id="footer.legal" />
          </Link>
        </span>
        <div className="separator-vertical-footer d-none d-nav-block" />
        <div className="separator-horizontal-footer d-block d-nav-none" />
        <span className="nav-item col-nav-auto d-flex justify-content-center">
          <Link className="nav-link text-white" to={`/${locale.locale}/privacy/`} activeClassName="fw-bold">
            <FormattedMessage id="footer.privacy" />
          </Link>
        </span>
      </div>
    </nav>
  )
}
export default Footer
