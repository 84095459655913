import "./src/themes/index.scss";
import 'bootstrap';

import React from "react";
import Layout from "./src/components/Layout";

export const wrapPageElement = ({ element, props }) => {
  //Do not wrap Layout on 404 pages to prevent errors
  if (Object.keys(props.pageContext).length === 0) {
    return undefined
  }
  else {
    return <Layout {...props}>{element}</Layout>;
  }
};
