import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { Helmet } from "react-helmet"

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Seo from './Seo'

var messages = {};
var flatten = require('flat')
const context = require.context("../../locales/", true, /.json$/, 'lazy');

context.keys().forEach((key) => {
  const path = key.replace(`./`, '');
  const lang = path.slice(0, 2);
  const fileName = path.slice(3).replace('.json', '');
  var object = {};
  object[fileName] = require(`../../locales/${path}`);
  const resource = flatten(object);
  var data = JSON.parse(JSON.stringify(resource));
  messages[lang] = { ...messages[lang], ...data };
});

const Layout = ({ pageContext: { locale, page }, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Fragment>
      <Seo locale={locale} />
      <Header locale={locale} page={page} />
      {children}
      <Footer locale={locale} />
    </Fragment>
  </IntlProvider>
)

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

export default Layout
