import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby'
import locales from '../../locales/locales.json'
import { FormattedMessage } from 'react-intl'
import { GrMenu } from "react-icons/gr";
import { GrClose } from 'react-icons/gr';

const Header = ({ locale, page }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const Collapse = require("bootstrap/js/dist/collapse");
    var myCollapse = document.getElementById('navbarCollapse')
    var bsCollapse = new Collapse(myCollapse, {
      toggle: false
    })
    document.addEventListener('wheel', () => bsCollapse.hide())
    document.addEventListener('touchmove', () => bsCollapse.hide())
    document.getElementById("navbarClose")?.addEventListener('click', (e) => bsCollapse.hide())
    document.getElementById("navbarOpen")?.addEventListener('click', (e) => bsCollapse.show())
    document.getElementById('navbarCollapse')?.addEventListener('hide.bs.collapse', (e) => setExpanded(false))
    document.getElementById('navbarCollapse')?.addEventListener('show.bs.collapse', (e) => setExpanded(true))
  })

  return (
    <nav className="navbar navbar-expand-nav sticky-top bg-white  px-lg-3 ">
      <div className="row row-cols-auto m-0 d-flex align-items-center ps-sm-2">
        <button className="navbar-toggler btn p-3 d-nav-none" type="button" aria-expanded="false" aria-label="Toggle navigation">
          <GrClose className={expanded ? "d-block" : "d-none"} id="navbarClose" size={16} />
          <GrMenu className={expanded ? "d-none" : "d-block"} id="navbarOpen" size={16} />
        </button>
        <div className="separator-vertical-header d-block d-nav-none " />
        <Link className="navbar-brand nav-link d-flex align-items-center m-0 col" to={`/${locale}/`} activeClassName="fw-bold">
          <img alt="Coneno Logo" width={16} src={"/favicons/coneno_logo_Bildmarke_4C.svg"} />
          <p className="m-0 ps-1">CONENO</p>
        </Link>
        <div className="separator-vertical-header d-none d-nav-block" />
      </div>

      <div className="d-flex flex-row order-nav-2 navbar-nav pe-sm-2 ">
        {Object.keys(locales).map((key, index) => {
          return (
            <div key={index} className="d-flex align-items-center">
              <li className="nav-item" key={locales[key].locale}>
                <Link
                  className={`nav-link ${key === locale ? 'text-decoration-underline fw-bold' : 'text-decoration-none fw-normal'}`}
                  key={locales[key].locale}
                  to={`/${locales[key].path}${page.path}`}
                >
                  {locales[key].locale}
                </Link>
              </li>
              {index === 0 ? <div key={"separator"} className="separator-vertical-header" /> : null}

            </div>
          )
        }
        )}
      </div>

      <div className="collapse navbar-collapse order-nav-1 " id="navbarCollapse">
        <ul className="navbar-nav align-items-center border-expanded">
          <li className="nav-item w-100">
            <a className="nav-link d-flex justify-content-center " href={`https://quikflow.app`} target="_blank" rel="noopener">
              <FormattedMessage id="header.quikflow" />
            </a>
          </li>
          <div className="separator-vertical-header d-none d-nav-block" />
          <div className="separator-horizontal-header d-block d-nav-none" />
          <li className="nav-item w-100">
            <Link className="nav-link d-flex justify-content-center " to={`/${locale}/case/`} activeClassName="fw-bold">
              <FormattedMessage id="header.case" />
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header
