import { metadata } from "core-js/fn/reflect"
import React from "react"
import { Helmet } from "react-helmet"


const SEO = (props) => {
  const metaData = require(`../../pages/seo.json`)

  return (
    <Helmet title={metaData[props.locale].title} >
      <meta name="description" content={metaData[props.locale].description} />
      <meta name="facebook-domain-verification" content="lsjfvzh059br88riwkok1lqzx1qak7" />
    </Helmet>
  )
}

export default SEO;
